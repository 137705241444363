import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, RadioGroup, FormControlLabel, Radio } from "@mui/material";

const TeamMembers = ({ members, onUpdate, onDelete }) => {
  const [editMember, setEditMember] = useState(null);

  const handleEditClick = (member) => setEditMember(member);

  const handleEditChange = (e) => {
    setEditMember({ ...editMember, [e.target.name]: e.target.value });
  };

  const handleRoleChange = (value) => {
    setEditMember({ ...editMember, role: value });
  };

  const handleEditSave = () => {
    onUpdate(editMember);
    setEditMember(null);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "role", headerName: "Role", width: 150 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "phone", headerName: "Phone", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <>
          <Button onClick={() => handleEditClick(params.row)} size="small">Edit</Button>
          <Button onClick={() => onDelete(params.row.id)} color="error" size="small">Delete</Button>
        </>
      ),
    },
  ];

  return (
    <>
      <div style={{ height: 320, width: "100%" }}>
        <DataGrid
          rows={members}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </div>
      {editMember && (
        <Dialog open={!!editMember} onClose={() => setEditMember(null)}>
          <DialogTitle>Edit Member</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Name"
              name="name"
              value={editMember.name}
              onChange={handleEditChange}
              fullWidth
            />
            <TextField
              margin="dense"
              label="Email"
              name="email"
              value={editMember.email}
              onChange={handleEditChange}
              fullWidth
            />
            <TextField
              margin="dense"
              label="Phone"
              name="phone"
              value={editMember.phone}
              onChange={handleEditChange}
              fullWidth
            />
            <RadioGroup
              value={editMember.role}
              onChange={(e) => handleRoleChange(e.target.value)}
              row
            >
              <FormControlLabel
                value="Sales Agent"
                control={<Radio />}
                label="Sales Agent"
              />
              <FormControlLabel
                value="Team Leader"
                control={<Radio />}
                label="Team Leader"
              />
              <FormControlLabel
                value="Collection Officer"
                control={<Radio />}
                label="Collection Officer"
              />
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditMember(null)}>Cancel</Button>
            <Button onClick={handleEditSave} color="primary">Save</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default TeamMembers;
