import React, { useState } from 'react';
import { TextField, Button, CircularProgress, Backdrop } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Navbar from "./Navbar";
import Footer from "./Footer";
import './BookDemo.css';

const BookDemo = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const navigate = useNavigate();

  // Define validation schema using Yup
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required('Full Name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    company: Yup.string().required('Company is required'),
    note: Yup.string(),
    selectedDate: Yup.date().nullable().required('Date is required'),
    selectedTime: Yup.date().nullable().required('Time is required'),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const bookedTime = values.selectedDate && values.selectedTime
        ? new Date(values.selectedDate).setHours(values.selectedTime.getHours(), values.selectedTime.getMinutes())
        : '';
      
      const response = await axios.post('http://127.0.0.1:5000/book-demo', {
        full_name: values.fullName,
        email: values.email,
        phone_number: values.phoneNumber,
        company: values.company,
        note: values.note,
        booked_time: new Date(bookedTime).toISOString(),
      });
      
      alert(response.data.message);
      navigate("/");
    } catch (error) {
      alert('Error: ' + (error.response?.data?.message || error.message));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="book-demo-container">
        <h2 className="form-title">Book a Demo With Us!</h2>
        <Formik
          initialValues={{
            fullName: '',
            email: '',
            phoneNumber: '',
            company: '',
            note: '',
            selectedDate: null,
            selectedTime: null,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, isSubmitting }) => (
            <>
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isSubmitting}
              >
                <CircularProgress color="inherit" />
              </Backdrop>

              <Form className="book-demo-form">
                <div className="form-grid">
                  <div className="form-field">
                    <Field as={TextField} label="Full Name" name="fullName" fullWidth margin="dense" />
                    <ErrorMessage name="fullName" component="div" className="error-message" />
                  </div>

                  <div className="form-field">
                    <Field as={TextField} label="Email" name="email" fullWidth margin="dense" />
                    <ErrorMessage name="email" component="div" className="error-message" />
                  </div>

                  <div className="form-field">
                    <Field as={TextField} label="Phone Number" name="phoneNumber" fullWidth margin="dense" />
                    <ErrorMessage name="phoneNumber" component="div" className="error-message" />
                  </div>

                  <div className="form-field">
                    <Field as={TextField} label="Company" name="company" fullWidth margin="dense" />
                    <ErrorMessage name="company" component="div" className="error-message" />
                  </div>

                  <div className="form-field full-width">
                    <Field as={TextField} label="Note" name="note" fullWidth margin="dense" multiline rows={1} />
                  </div>

                  <div className="form-field">
                    <label>Select Date</label>
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => {
                        setSelectedDate(date);
                        setFieldValue('selectedDate', date);
                      }}
                      dateFormat="MMMM d, yyyy"
                      minDate={new Date()}
                      filterDate={(date) => date.getDay() !== 0 && date.getDay() !== 6}
                      placeholderText="Select a date"
                      className="custom-datepicker"
                    />
                    <ErrorMessage name="selectedDate" component="div" className="error-message" />
                  </div>

                  <div className="form-field">
                    <label>Select Time</label>
                    <DatePicker
                      selected={selectedTime}
                      onChange={(time) => {
                        setSelectedTime(time);
                        setFieldValue('selectedTime', time);
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      minTime={new Date().setHours(9, 0)}
                      maxTime={new Date().setHours(17, 0)}
                      placeholderText="Select a time"
                      className="custom-timepicker"
                    />
                    <ErrorMessage name="selectedTime" component="div" className="error-message" />
                  </div>
                </div>
                <Button type="submit" color="primary" variant="contained" style={{ marginTop: '20px' }} disabled={isSubmitting}>
                  Book
                </Button>
              </Form>
            </>
          )}
        </Formik>
      </div>
      <div className="footer"> 
      <Footer />
      </div>
    </>
  );
};

export default BookDemo;