
import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from 'js-cookie';
import TeamMembers from "./TeamMembers";
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, IconButton,Snackbar, Alert } from "@mui/material"; 
import { Edit, Delete } from "@mui/icons-material";
import "./teammanagement.css";
import CsvUploader from "./CsvUploader";

const TeamManagement = () => {
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [teamMembers, setTeamMembers] = useState([]);
  const [newUser, setNewUser] = useState({ name: "", role: "", email: "", phone: "" }); // Added email & phone
  const [roles, setRoles] = useState({ teamLeader: false, collectionOfficer: false });
  const [newTeam, setNewTeam] = useState("");
  const [openDialog, setOpenDialog] = useState(false); // State for dialog
  const [openCsvDialog, setOpenCsvDialog] = useState(false);
  const [openManageDialog, setOpenManageDialog] = useState(false);
  const [editTeamId, setEditTeamId] = useState(null);
  const [editTeamName, setEditTeamName] = useState("");
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });


  let accessToken = Cookies.get('accessToken');

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/teams`,
        {
          withCredentials: true,
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        }
      );
      const teamsData = response.data;
      setTeams(Array.isArray(teamsData) ? teamsData : [teamsData]);
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };


  const handleMemberUpdate = async (member) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/teams/${selectedTeam}/members/${member.id}`, member, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      fetchTeamMembers(selectedTeam);
      setSnackbar({ open: true, message: "Member updated successfully!", severity: "success" });
    } catch (error) {
      console.error("Error updating member:", error);
      setSnackbar({ open: true, message: "Failed to update member!", severity: "error" });
    }
  };

  const handleSnackbarClose = () => setSnackbar({ ...snackbar, open: false });

  const handleMemberDelete = async (memberId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/teams/${selectedTeam}/members/${memberId}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      fetchTeamMembers(selectedTeam);
      setSnackbar({ open: true, message: "Member deleted successfully!", severity: "success" });
    } catch (error) {
      console.error("Error deleting member:", error);
      setSnackbar({ open: true, message: "Failed to delete member!", severity: "error" });
    }
  };
  
  const handleTeamClick = (teamId) => {
    setSelectedTeam(teamId);
    fetchTeamMembers(teamId);
    setOpenDialog(true); // Open dialog on team click
  };

  const fetchTeamMembers = async (teamId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/teams/${teamId}/members`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      setTeamMembers(response.data);
      updateRoleAvailability(response.data);
    } catch (error) {
      console.error("Error fetching team members:", error);
    }
  };

  const updateRoleAvailability = (members) => {
    const teamLeaderExists = members.some((member) => member.role === "Team Leader");
    const collectionOfficerExists = members.some((member) => member.role === "Collection Officer");

    setRoles({
      teamLeader: teamLeaderExists,
      collectionOfficer: collectionOfficerExists,
    });
  };

  const handleUserChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleCsvDialogClose = () => {
    setOpenCsvDialog(false);
  };

  const handleUserSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/teams/${selectedTeam}/add-member`, newUser, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      fetchTeamMembers(selectedTeam);
      setNewUser({ name: "", role: "", email: "", phone: "" }); // Reset after submit
      setSnackbar({ open: true, message: "Team member created successfully!", severity: "success" });
    } catch (error) {
      console.error("Error adding user:", error);
      setSnackbar({ open: true, message: "Failed to create team member!", severity: "error" });
    }
  };

  const handleTeamCreation = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/teams`, { name: newTeam }, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      setNewTeam("");
      fetchTeams();
      setSnackbar({ open: true, message: "Team created successfully!", severity: "success" });
    } catch (error) {
      console.error("Error creating team:", error);
      setSnackbar({ open: true, message: "Failed to create team!", severity: "error" });
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false); // Close the dialog
    setSelectedTeam(""); // Clear selected team
  };


  const handleOpenManageDialog = () => {
    setOpenManageDialog(true);
  };

  const handleCloseManageDialog = () => {
    setOpenManageDialog(false);
    setEditTeamId(null);
    setEditTeamName("");
  };
  const handleDeleteTeam = async (teamId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/teams/${teamId}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      setTeams(teams.filter((team) => team.id !== teamId));
      setSnackbar({ open: true, message: "Team deleted successfully!", severity: "success" });
    } catch (error) {
      console.error("Error deleting team:", error);
      setSnackbar({ open: true, message: "Failed to delete team!", severity: "error" });
    }
  };


  const handleEditTeam = (team) => {
    setEditTeamId(team.id);
    setEditTeamName(team.name);
  };

  const handleSaveEditTeam = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/teams/${editTeamId}`, { name: editTeamName }, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      setTeams(teams.map((team) => (team.id === editTeamId ? { ...team, name: editTeamName } : team)));
      setEditTeamId(null);
      setEditTeamName("");
      setSnackbar({ open: true, message: "Team updated successfully!", severity: "success" });
    } catch (error) {
      console.error("Error updating team:", error);
      setSnackbar({ open: true, message: "Failed to update team!", severity: "error" });
    }
  };

  const getSnackbarStyle = (severity) => {
    switch (severity) {
      case "success":
        return { backgroundColor: "green", color: "white" };
      case "error":
        return { backgroundColor: "red", color: "white" };
      default:
        return { backgroundColor: "blue", color: "white" };
    }
  };

  return (
    <div className="team-management-container">
      <h2>Team Management</h2> 
      <div className="team-block">
        <div className="form-block">
          <h4>Create a Team</h4>
          <form onSubmit={handleTeamCreation}>
            <input
              type="text"
              name="team"
              placeholder="Enter team name"
              value={newTeam}
              onChange={(e) => setNewTeam(e.target.value)}
              required
            />
            <button type="submit">Create Team</button>
          </form>
          
        </div>
 
      </div>

      <div className="teams-container">
        <h4>Your Teams</h4>
        <div className="manage-team">
            <button type="submit" onClick={handleOpenManageDialog}>Manage  Team</button>
            
        </div>

        <Dialog
        open={openManageDialog}
        onClose={handleCloseManageDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Manage Teams</DialogTitle>
        <DialogContent>
          {teams.map((team) => (
            <div
              key={team.id}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              {editTeamId === team.id ? (
                <TextField
                  value={editTeamName}
                  onChange={(e) => setEditTeamName(e.target.value)}
                  fullWidth
                />
              ) : (
                <Typography variant="body1">{team.name}</Typography>
              )}
              <div>
                {editTeamId === team.id ? (
                  <Button
                    color="primary"
                    onClick={handleSaveEditTeam}
                    style={{ marginRight: "10px" }}
                  >
                    Save
                  </Button>
                ) : (
                  <IconButton
                    color="primary"
                    onClick={() => handleEditTeam(team)}
                  >
                    <Edit />
                  </IconButton>
                )}
                <IconButton
                  color="secondary"
                  onClick={() => handleDeleteTeam(team.id)}
                >
                  <Delete />
                </IconButton>
              </div>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseManageDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

        

        <div className="team-grid">
          {teams.map((team) => (
            <div 
              key={team.id} 
              className="team-card" 
              onClick={() => handleTeamClick(team.id)}
            >
              <h5>{team.name}</h5>
            </div>
          ))}
        </div>
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenCsvDialog(true)}
        style={{ marginTop: "20px" }}
      >
        Upload CSV
      </Button>

      {/* Dialog for adding members */}
      <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="md" fullWidth>
        <DialogTitle>Manage Team Members for </DialogTitle>
        <DialogContent>
          <div className="members-container">
            <h4>Add User to Team </h4>
            <form onSubmit={handleUserSubmit}>
              <input
                type="text"
                name="name"
                placeholder="User Name"
                value={newUser.name}
                onChange={handleUserChange}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="User Email"
                value={newUser.email}
                onChange={handleUserChange}
                required
              />
              <input
                type="text"
                name="phone"
                placeholder="Phone Number"
                value={newUser.phone}
                onChange={handleUserChange}
                required
              />
              <select
                name="role"
                value={newUser.role}
                onChange={handleUserChange}
                required
              >
                <option value="">Select Role</option>
                <option value="Sales Agent">Sales Agent</option>
                <option value="Team Leader" disabled={roles.teamLeader}>
                  Team Leader
                </option>
                <option value="Collection Officer" disabled={roles.collectionOfficer}>
                  Collection Officer
                </option>
              </select>
              <button type="submit">Add User</button>
            </form>

            <Typography variant="h6" style={{ marginTop: '20px', marginBottom: '3px' }}>
              Team Members
            </Typography>
            {/* <TeamMembers members={teamMembers} /> */}
            <TeamMembers 
  members={teamMembers} 
  onUpdate={handleMemberUpdate} 
  onDelete={handleMemberDelete} 
/>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
      {/* Dialog for CSV Uploader */}
      <Dialog
        open={openCsvDialog}
        onClose={handleCsvDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Upload CSV File</DialogTitle>
        <DialogContent>
          <CsvUploader />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCsvDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Set position to top center
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={getSnackbarStyle(snackbar.severity)}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default TeamManagement;



