import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://carren.cc/">
        Carren Technologies Ltd.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // success or error
  const [loading, setLoading] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Check if the email exists
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/check-email?email=${email}`);
      const data = await response.json();

      if (data.exists) {
        // Attempt to send reset password email
        const sendResetEmailResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/send-reset-link`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });

        const sendResetEmailData = await sendResetEmailResponse.json();
        
        if (sendResetEmailResponse.ok) {
          setSnackbarSeverity('success');
          setSnackbarMessage("Link to password reset has been sent to your registered email.");
        } else {
          setSnackbarSeverity('error');
          setSnackbarMessage(sendResetEmailData.error || "Failed to send reset password email.");
        }
      } else {
        setSnackbarSeverity('error');
        setSnackbarMessage("Email not found. Please enter a registered email address.");
      }
    } catch (error) {
      setSnackbarSeverity('error');
      setSnackbarMessage("Failed to send reset password email. Please try again later.");
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        backgroundColor: '#fff',
        p: 4,
        fontSize: 14,
        fontFamily: 'inherit',
        color: '#212121',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRadius: 2,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1)',
        m: '30px auto',
        textAlign: 'center',
      }}
    >
      <Typography variant="h4" fontWeight="bold" color="black" mb={2}>
        Carren.
      </Typography>
      <LockOpenIcon sx={{ fontSize: 40, mb: 2, mx: 'auto' }} />
      <Typography variant="h5" mb={3}>
        Forgot Password
      </Typography>

      <form onSubmit={handleSubmit}>
        <TextField
          type="email"
          id="email"
          name="email"
          label="Email"
          placeholder="Please enter registered email address"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          fullWidth
          sx={{ mb: 2 }}
        />

        <Button
          variant="contained"
          fullWidth
          type="submit"
          disabled={loading}
          sx={{
            py: 1,
            backgroundColor: '#161C24',
            '&:hover': {
              backgroundColor: '#212B36',
            },
          }}
        >
          {loading ? "Sending..." : "Send Email"}
        </Button>
      </form>

      <Link to="/signin"> Back to Sign In</Link>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Copyright sx={{ mt: 4 }} />
    </Box>
  );
};

export default ForgotPasswordForm;
