
import React, { useState } from 'react';
import { Box, Button, Typography, TextField, CircularProgress, Alert } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from 'axios';

const CsvUploader = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setMessage('');
    setError(false);
  };

  const handleUpload = async () => {
    if (!file) {
      setError(true);
      setMessage('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    setUploading(true);
    setMessage('');
    setError(false);

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/upload_data`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
          

      if (response.status === 200) {
        setMessage(response.data.message || 'File uploaded successfully.');
      } else {
        setError(true);
        setMessage(response.data.error || 'An error occurred during upload.');
      }
    } catch (err) {
      setError(true);
      setMessage('Duplicate file upload attempt detected');
    } finally {
      setUploading(false);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ p: 3, border: '1px solid #ccc', borderRadius: 2, maxWidth: 400, m: 'auto' }}
    >
      <Typography variant="h5" gutterBottom>
        Upload CSV File
      </Typography>
      <TextField
        type="file"
        inputProps={{ accept: '.csv,.xlsx' }}
        onChange={handleFileChange}
        sx={{ mb: 2 }}
      />
      <Button
        variant="contained"
        startIcon={<CloudUploadIcon />}
        onClick={handleUpload}
        disabled={uploading}
      >
        {uploading ? <CircularProgress size={24} /> : 'Upload'}
      </Button>
      {message && (
        <Alert severity={error ? 'error' : 'success'} sx={{ mt: 2, width: '100%' }}>
          {message}
        </Alert>
      )}
    </Box>
  );
};

export default CsvUploader;
